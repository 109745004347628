'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('turbine', {
        url: '/turbine/:id',
        template: '<turbine></turbine>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
